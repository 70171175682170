//FETCH_LIST Actions
export const FETCH_CATEGORYLIST_SUCCESS = "FETCH_CATEGORYLIST_SUCCESS";
export const FETCH_CATEGORYLIST_ERROR = "FETCH_CATEGORYLIST_ERROR";
//PAGE_CHANGE Actions
export const CATEGORY_PAGE_CHANGE = "CATEGORY_PAGE_CHANGE"
//LIMIT_CHANGE Actions
export const CATEGORY_LIMIT_CHANGE = "CATEGORY_LIMIT_CHANGE"
//DEL_CATEGORY Actions
export const DEL_CATEGORY_SUCCESS = "DEL_CATEGORY_SUCCESS";
export const DEL_CATEGORY_ERROR = "DEL_CATEGORY_ERROR";
//CATEGORY_ACTIVE_STATUS Actions
export const CATEGORY_ACTIVE_STATUS_SUCCESS = "CATEGORY_ACTIVE_STATUS_SUCCESS";
export const CATEGORY_ACTIVE_STATUS_ERROR = "CATEGORY_ACTIVE_STATUS_ERROR";
//FETCH_CATEGORY Actions
export const FETCH_CATEGORY_INFO_SUCCESS = "FETCH_CATEGORY_INFO_SUCCESS";
export const FETCH_CATEGORY_INFO_ERROR = "FETCH_CATEGORY_INFO_ERROR";
//EDIT_CATEGORY Actions
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_ERROR = "EDIT_CATEGORY_ERROR";
//add CATEGORY ACTIONS
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";