import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    deliveryList: { recordsTotal: 0 },
    delivery: {},
    page: 1,
    limit: 10,
}

export const DeliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_DELIVERY_LIST_SUCCESS:
            return {
                ...state,
                deliveryList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.DELIVERY_ACTIVE_STATUS_SUCCESS:
            return {
                ...state,
                delivery: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.EDIT_DELIVERY_SUCCESS:
            return {
                ...state,
                delivery: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_DELIVERY_LIST_ERROR:
        case ACTION_TYPES.DELIVERY_ACTIVE_STATUS_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message,
                error: action?.payload?.data?.message,
                isEdited: false
            }
        case ACTION_TYPES.EDIT_DELIVERY_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }

        default:
            return state
    }
}

