import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  eventList: { recordsTotal: 0 },
  event: {},
  allState: {},
  allCounty: {},
  page: 1,
  limit: 10,
};

export const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_EVENT_SUCCESS:
      return {
        ...state,
        eventList: action.payload.data,
        message: action.payload.message,
        error: null,
      };
    case ACTION_TYPES.EVENT_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload,
      };
    case ACTION_TYPES.EVENT_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload,
      };
    case ACTION_TYPES.FETCH_EVENT_INFO_SUCCESS:
      return {
        ...state,
        event: action.payload.data,
        message: action.payload.message,
        isEdited: false,
        error: null,
      };
    case ACTION_TYPES.FETCH_EVENT_INFO_ERROR:
      return {
        ...state,
        event: null,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    case ACTION_TYPES.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        event: action.payload.data,
        message: action.payload.message,
        isEdited: true,
        error: null,
      };
    case ACTION_TYPES.FETCH_EVENT_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data.message,
        isEdited: false,
      };
    case ACTION_TYPES.EDIT_EVENT_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data.message,
        isEdited: false,
      };
    case ACTION_TYPES.GET_STATELIST_SUCCESS:
      return {
        ...state,
        allState: action.payload.data,
        message: action.payload.message,
      };
    case ACTION_TYPES.GET_STATELIST_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    case ACTION_TYPES.GET_COUNTYLIST_SUCCESS:
      return {
        ...state,
        allCounty: action.payload.data,
        message: action.payload.message,
      };
    case ACTION_TYPES.GET_COUNTYLIST_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    default:
      return state;
  }
};
