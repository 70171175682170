//FETCH_LIST Actions
export const FETCH_ACCOUNT_TYPE_SUCCESS = "FETCH_ACCOUNT_TYPE_SUCCESS";
export const FETCH_ACCOUNT_TYPE_ERROR = "FETCH_ACCOUNT_TYPE_ERROR";
//PAGE_CHANGE Actions
export const ACCOUNT_TYPE_PAGE_CHANGE = "ACCOUNT_TYPE_PAGE_CHANGE"
//LIMIT_CHANGE Actions
export const ACCOUNT_TYPE_LIMIT_CHANGE = "ACCOUNT_TYPE_LIMIT_CHANGE"
//DEL_ACCOUNT_TYPE Actions
export const DEL_ACCOUNT_TYPE_SUCCESS = "DEL_ACCOUNT_TYPE_SUCCESS";
export const DEL_ACCOUNT_TYPE_ERROR = "DEL_ACCOUNT_TYPE_ERROR";
//ACCOUNT_TYPE_ACTIVE_STATUS Actions
export const ACCOUNT_TYPE_ACTIVE_STATUS_SUCCESS = "ACCOUNT_TYPE_ACTIVE_STATUS_SUCCESS";
export const ACCOUNT_TYPE_ACTIVE_STATUS_ERROR = "ACCOUNT_TYPE_ACTIVE_STATUS_ERROR";
//FETCH_ACCOUNT_TYPE Actions
export const FETCH_ACCOUNT_TYPE_INFO_SUCCESS = "FETCH_ACCOUNT_TYPE_INFO_SUCCESS";
export const FETCH_ACCOUNT_TYPE_INFO_ERROR = "FETCH_ACCOUNT_TYPE_INFO_ERROR";
//EDIT_ACCOUNT_TYPE Actions
export const EDIT_ACCOUNT_TYPE_SUCCESS = "EDIT_ACCOUNT_TYPE_SUCCESS";
export const EDIT_ACCOUNT_TYPE_ERROR = "EDIT_ACCOUNT_TYPE_ERROR";
//add ACCOUNT_TYPE ACTIONS
export const ADD_ACCOUNT_TYPE_SUCCESS = "ADD_ACCOUNT_TYPE_SUCCESS";
export const ADD_ACCOUNT_TYPE_ERROR = "ADD_ACCOUNT_TYPE_ERROR";
