import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    cmsData: [],
    isEdited: false,
}

export const AgentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_CMS_DATA_SUCCESS:
            return {
                ...state,
                cmsData: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.UPDATE_CMS_DATA_SUCCESS:
            return {
                ...state,
                cmsData: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_CMS_DATA_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.UPDATE_CMS_DATA_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        default:
            return state
    }
}

