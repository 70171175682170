import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import checkAuth from './Authentication/Auth'
import { toast } from 'react-toastify'
import ReduxToastr from "react-redux-toastr";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import * as ACTIONS from "./store/Login/Action"
import { AUTHENTICATE_FAILED } from "./store/Login/Types"
import store from "./store"
import "./config/AxiosConfig"
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
// const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/forgotPassword'))
// toast alert
toast.configure()
//reauthenticate
const token = JSON.parse(localStorage.getItem("authToken"));
const loggedInUser = JSON.parse(localStorage.getItem("user"));


if (token && loggedInUser) {
  store.dispatch(ACTIONS.authenticate(loggedInUser));
} else {
  store.dispatch({ type: AUTHENTICATE_FAILED });
}



function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ReduxToastr
        position="bottom-right" />
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          {/* <Route exact path="/forgotpassword" name="forgot password" render={props => <ForgotPassword {...props} />} /> */}
          <Route path="/" name="Home" component={checkAuth(TheLayout)} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}


export default App;
