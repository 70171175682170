import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  ticketList: { recordsTotal: 0 },
  ticket: {},
  allState: {},
  allCounty: {},
  page: 1,
  limit: 10,
};

export const TicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TICKET_SUCCESS:
      return {
        ...state,
        ticketList: action.payload.data,
        message: action.payload.message,
        error: null,
      };
    case ACTION_TYPES.TICKET_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload,
      };
    case ACTION_TYPES.TICKET_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload,
      };
    case ACTION_TYPES.FETCH_TICKET_INFO_SUCCESS:
      return {
        ...state,
        ticket: action.payload.data,
        message: action.payload.message,
        isEdited: false,
        error: null,
      };
    case ACTION_TYPES.FETCH_TICKET_INFO_ERROR:
      return {
        ...state,
        ticket: null,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    case ACTION_TYPES.GET_STATELIST_SUCCESS:
      return {
        ...state,
        allState: action.payload.data,
        message: action.payload.message,
      };
    case ACTION_TYPES.GET_STATELIST_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    case ACTION_TYPES.GET_COUNTYLIST_SUCCESS:
      return {
        ...state,
        allCounty: action.payload.data,
        message: action.payload.message,
      };
    case ACTION_TYPES.GET_COUNTYLIST_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    default:
      return state;
  }
};
