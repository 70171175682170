import { changeState } from "./common/changeStateReducer";
import { loginReducer } from "./Login/Reducer";
import { adminReducer } from "./adminProfile/Reducer";
import { UserReducer } from "./User/Reducer";
import { AgentReducer } from "./CMS/Reducer";
import { ReportedUserReducer } from "./ReportedUser/Reducer";
import { CategoryReducer } from "./Category/Reducer";
import { ProductReducer } from "./Product/Reducer";
import { PushNotificationReducer } from "./PushNotification/Reducer";
import { MarketReducer } from "./FarmerMarket/Reducer";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers } from "redux";
import { DeliveryReducer } from "./Delivery/Reducer";
import { OrderReducer } from "./Order/Reducer";
import { UnitReducer } from "./Unit/Reducer";
import { TicketReducer } from "./Ticket/Reducer";
import { EventReducer } from "./Event/Reducer";
import { CauseReducer } from "./Cause/Reducer";
import { ForceReducer } from "./ForceUpdate/Reducer";
import { NonFoodRateReducer } from "./NonFoodRate/Reducer";
import { AccountTypeReducer } from "./AccountType/Reducer";
import { SubtypeReducer } from "./subtype/Reducer";
export const rootReducer = combineReducers({
  changeState: changeState,
  toastr: toastrReducer,
  form: formReducer,
  login: loginReducer,
  admin: adminReducer,
  user: UserReducer,
  cms: AgentReducer,
  reported: ReportedUserReducer,
  category: CategoryReducer,
  nonFoodRate: NonFoodRateReducer,
  accountType: AccountTypeReducer,
  subtype: SubtypeReducer,
  product: ProductReducer,
  push: PushNotificationReducer,
  market: MarketReducer,
  order: OrderReducer,
  delivery: DeliveryReducer,
  unit: UnitReducer,
  ticket: TicketReducer,
  event: EventReducer,
  cause: CauseReducer,
  forceUpadte: ForceReducer
});
