//FETCH_LIST Actions
export const FETCH_SUBTYPE_SUCCESS = "FETCH_SUBTYPE_SUCCESS";
export const FETCH_SUBTYPE_ERROR = "FETCH_SUBTYPE_ERROR";
//PAGE_CHANGE Actions
export const SUBTYPE_PAGE_CHANGE = "SUBTYPE_PAGE_CHANGE"
//LIMIT_CHANGE Actions
export const SUBTYPE_LIMIT_CHANGE = "SUBTYPE_LIMIT_CHANGE"
//DEL_SUBTYPE Actions
export const DEL_SUBTYPE_SUCCESS = "DEL_SUBTYPE_SUCCESS";
export const DEL_SUBTYPE_ERROR = "DEL_SUBTYPE_ERROR";
//SUBTYPE_ACTIVE_STATUS Actions
export const SUBTYPE_ACTIVE_STATUS_SUCCESS = "SUBTYPE_ACTIVE_STATUS_SUCCESS";
export const SUBTYPE_ACTIVE_STATUS_ERROR = "SUBTYPE_ACTIVE_STATUS_ERROR";
//FETCH_SUBTYPE Actions
export const FETCH_SUBTYPE_INFO_SUCCESS = "FETCH_SUBTYPE_INFO_SUCCESS";
export const FETCH_SUBTYPE_INFO_ERROR = "FETCH_SUBTYPE_INFO_ERROR";
//EDIT_SUBTYPE Actions
export const EDIT_SUBTYPE_SUCCESS = "EDIT_SUBTYPE_SUCCESS";
export const EDIT_SUBTYPE_ERROR = "EDIT_SUBTYPE_ERROR";
//add SUBTYPE ACTIONS
export const ADD_SUBTYPE_SUCCESS = "ADD_SUBTYPE_SUCCESS";
export const ADD_SUBTYPE_ERROR = "ADD_SUBTYPE_ERROR";