import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    reporteduserList: { recordsTotal: 0 },
    user: {},
    page: 1,
    limit: 10,
}

export const ReportedUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_REPORTED_USERSUCCESS:
            return {
                ...state,
                reporteduserList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.REPORTED_USER_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.REPORTED_USER_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.GET_REPORTED_USERERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        default:
            return state
    }
}

