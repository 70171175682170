import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    userList: { recordsTotal: 0 },
    user: {},
    page: 1,
    limit: 10,
}

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_LIST_SUCCESS:
            return {
                ...state,
                userList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.USER_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.USER_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.DEL_USER_SUCCESS:
        case ACTION_TYPES.ADD_USER_SUCCESS:
        case ACTION_TYPES.FETCH_USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }
        case ACTION_TYPES.USER_ACTIVE_STATUS_SUCCESS:
        case ACTION_TYPES.USER_VERIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.EDIT_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.FETCH_LIST_ERROR:
        case ACTION_TYPES.DEL_USER_ERROR:
        case ACTION_TYPES.ADD_USER_ERROR:
        case ACTION_TYPES.USER_ACTIVE_STATUS_ERROR:
        case ACTION_TYPES.USER_VERIFICATION_STATUS_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message,
                error: action?.payload?.data?.message,
                isEdited: false
            }
        case ACTION_TYPES.FETCH_USER_INFO_ERROR:
            return {
                ...state,
                user: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.EDIT_USER_ERROR:
            return {
                ...state,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }

        default:
            return state
    }
}

