//FETCH_LIST Actions
export const FETCH_FARMERMARKET_SUCCESS = "FETCH_FARMERMARKET_SUCCESS";
export const FETCH_FARMERMARKET_ERROR = "FETCH_FARMERMARKET_ERROR";
//PAGE_CHANGE Actions
export const MARKET_PAGE_CHANGE = "MARKET_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const MARKET_LIMIT_CHANGE = "MARKET_LIMIT_CHANGE"

//FETCH_MARKET Actions
export const FETCH_MARKET_INFO_SUCCESS = "FETCH_MARKET_INFO_SUCCESS";
export const FETCH_MARKET_INFO_ERROR = "FETCH_MARKET_INFO_ERROR";
//EDIT_MARKET Actions
export const EDIT_MARKET_SUCCESS = "EDIT_MARKET_SUCCESS";
export const EDIT_MARKET_ERROR = "EDIT_MARKET_ERROR";

//get event
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

//add event

export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_ERROR = "ADD_EVENT_ERROR";

//edit event

export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_ERROR = "EDIT_EVENT_ERROR";


//add MARKET ACTIONS
export const ADD_MARKET_SUCCESS = "ADD_MARKET_SUCCESS";
export const ADD_MARKET_ERROR = "ADD_MARKET_ERROR";
//get state
export const GET_STATELIST_SUCCESS = "GET_STATELIST_SUCCESS";
export const GET_STATELIST_ERROR = "GET_STATELIST_ERROR";
//GET county
export const GET_COUNTYLIST_SUCCESS = "GET_COUNTYLIST_SUCCESS";
export const GET_COUNTYLIST_ERROR = "GET_COUNTYLIST_ERROR";