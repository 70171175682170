import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    versionFList: {},
    versionF: {},
    versionGList: {},
    versionG: {},


}

export const ForceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_GROWER_VERSION_SUCCESS:
            return {
                ...state,
                versionGList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_FETCHER_VERSION_SUCCESS:
            return {
                ...state,
                versionFList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.UPDATE_FETCHER_VERSION_SUCCESS:
            return {
                ...state,
                versionF: action.payload.data,
                message: action.payload.message,
                isEdited: true,
                error: null
            }
        case ACTION_TYPES.UPDATE_GROWER_VERSION_SUCCESS:
            return {
                ...state,
                versionG: action.payload.data,
                message: action.payload.message,
                isEdited: false,
                error: null
            }

        default:
            return state
    }
}

